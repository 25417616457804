const jokeList = [
  "Aspiring Professional Pillow Fluffer",
  "Aspiring Unicorn Wrangler",
  "Aspiring Pizza Taste Tester",
  "Aspiring Galactic Chocolate Consultant",
  "Aspiring Emoji Interpretator",
  "Aspiring Professional Ice Cream Flavor Inventor",
  "Aspiring World Champion Paper Airplane Pilot",
  "Aspiring Cat Video Curator",
  "Aspiring Professional Cloud Watcher",
  "Aspiring Captain of the Starship Snacketeria",
  "Aspiring Pajama Fashionista",
  "Aspiring Sock Puppet Theatre Director",
  "Aspiring Professional Bubble Wrap Popper",
  "Aspiring Expert in Microwave Culinary Arts",
  "Aspiring Lunar Travel Agent",
  "Aspiring Hula Hoop Spinner",
  "Aspiring Cupcake Taste Tester",
  "Aspiring Captain of the S.S. Sillypants",
  "Aspiring Chief Imaginator",
  "Aspiring Grand Puzzlemaster",
  "Aspiring Head of Nonsense Engineering",
  "Aspiring Professional Bubble Gum Blower",
  "Aspiring Supreme High Fiver",
  "Aspiring Headphone Tangler",
  "Aspiring Jigsaw Puzzle Solver",
  "Aspiring Silly Dance Choreographer",
  "Aspiring Awkward Silence Curator",
  "Aspiring Dream Architect",
  "Aspiring Ventriloquist",
  "Aspiring Word Inventor",
  "Aspiring Blanket Fort Architect",
  "Aspiring Water Balloon Fight Instigator",
  "Aspiring Proponent of Silly Hats",
  "Aspiring Cookie Dunker",
  "Aspiring Sock Matcher",
  "Aspiring Marshmallow Roaster",
  "Aspiring Professional Face Painter",
  "Aspiring Ice Cream Sundae Sculptor",
  "Aspiring Synchronized Spoon Clanker",
  "Aspiring Fortune Cookie Message Writer",
  "Aspiring Coffee Snob",
  "Aspiring Tic-Tac-Toe Winner",
  "Aspiring Galactic Donut",
  "Aspiring Pizza Dough Spinner",
  "Aspiring Origami Swan Folder",
  "Aspiring Cupcake Juggler",
  "Aspiring Cricket Orchestra Conductor",
  "Aspiring Robot Dog Walker",
  "Aspiring Oreo Stacker",
  "Aspiring Paranormal Investigator",
  "Aspiring B-Movie Reviewer",
  "Aspiring Soap Boiler",
  "Aspiring Human Statue",
  "Aspiring Paint-by-Numbers Artist",
  "Aspiring Paint-Drying Inspector",
  "Aspiring Balalaika Player",
  "Aspiring Sticker Collector",
  "Aspiring Burger Smasher",
  "Aspiring Cat Therapist",
  "Aspiring Tortise Racer",
  "Aspiring Plant Debate Moderator",
];

export const randomJoke = () => {
  const randomIndex = Math.floor(Math.random() * jokeList.length);
  return jokeList[randomIndex];
};
