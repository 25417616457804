import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Item from "./Item";

const Section = ({ section }) => {
  const [showOld, setShowOld] = React.useState(false);

  const itemsToShow = showOld
    ? section.items
    : section.items.filter((item) => parseInt(item.date.slice(-4)) > 2018);

  const shouldShowOldButton = section.items.length > itemsToShow.length;

  return (
    <section id={section.id}>
      <Container>
        <Row>
          <Col>
            <article>
              <header>
                <h2>{section.title}</h2>
              </header>
              {itemsToShow.map((item, index) => (
                <Item key={index} item={item} />
              ))}
              {!showOld && shouldShowOldButton && (
                <div className="showOldButtonWrapper">
                  <button
                    className="showOldButton"
                    onClick={() => setShowOld(true)}
                  >
                    Show Older
                  </button>
                </div>
              )}
            </article>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section;
